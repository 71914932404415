@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('../src/css/fonts.css');
@font-face {
  font-family: "SuperbetSans";
  src: url('../src/Fonts/SuperbetSans-Black.otf');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "SuperbetSans";
  src: url('../src/Fonts/SuperbetSans-Bold.otf');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SuperbetSans";
  src: url('../src/Fonts/SuperbetSans-SemiBold.otf');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SuperbetSans";
  src: url('../src/Fonts/SuperbetSans-Regular.otf');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SuperbetSans";
  src: url('../src/Fonts/SuperbetSans-Light.otf');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

.container-custom{
  @apply max-w-[1170px] mx-auto px-5;
}